import { nowInSec, SkyWayAuthToken, SkyWayContext, SkyWayRoom, SkyWayStreamFactory, uuidV4 } from '@skyway-sdk/room';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

var me;
var remoteMediaArea;
var remoteAudioArea;
var localVideo;
var buttonArea;

const token = new SkyWayAuthToken({
  jti: uuidV4(),
  iat: nowInSec(),
  exp: nowInSec() + 15 * 60 * 1,
  scope: {
    app: {
      id: '77592615-1b3b-49dc-a842-cafafcb547b9',
      turn: true,
      actions: ['read'],
      channels: [
        {
          id: '*',
          name: '*',
          actions: ['write'],
          members: [
            {
              id: '*',
              name: '*',
              actions: ['write'],
              publication: {
                actions: ['write'],
              },
              subscription: {
                actions: ['write'],
              },
            },
          ],
          sfuBots: [
            {
              actions: ['write'],
              forwardings: [
                {
                  actions: ['write'],
                },
              ],
            },
          ],
        },
      ],
    },
  },
}).encode('/TVpFlY4Yklsg/6Lcm64Fo7gU8gkJRYRDPvq7X78/FM=');

(async () => {
  localVideo = document.getElementById('local-video');
  buttonArea = document.getElementById('button-area');
  remoteMediaArea = document.getElementById('remote-media-area');
  remoteAudioArea = document.getElementById('remote-audio-area');
  //const roomNameInput = document.getElementById('room-name');
  var params = (new URL(document.location)).searchParams;
  var get_id = params.get('id');
  console.log('get_id :', get_id);
  var eptime = params.get('time');
  console.log('eptime :', eptime);

  //const roomNameInput = document.getElementById('room-name');
  const roomNameInput = get_id;

  const myId = document.getElementById('my-id');
  const joinButton = document.getElementById('join');

  const { audio, video } = await SkyWayStreamFactory.createMicrophoneAudioAndCameraStream();
  video.attach(localVideo);
  await localVideo.play();

  joinButton.onclick = async () => {
    //if (roomNameInput.value === '') return;
    if (get_id === '') return;

    if (joinButton.textContent === '通話中'){ 
      Toastify({
        text: "通話待ち中です。",
        duration: 3000,
        destination: "",
        newWindow: true,
        close: false,
        gravity: "top", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
        },
        //onClick: function(){} // Callback after click
      }).showToast();

      return;
    }

    joinButton.textContent = '通話中';

    const context = await SkyWayContext.Create(token, {
      log: {
        level: 'error' //'debug'
      }
    });
    const room = await SkyWayRoom.FindOrCreate(context, {
      type: 'p2p',
      name: get_id,
    });
    //const me = await room.join();
    me = await room.join();

    myId.textContent = me.id;

    await me.publish(audio);
    await me.publish(video);

    const subscribeAndAttach = (publication) => {
      if (publication.publisher.id === me.id) return;

      const subscribeButton = document.createElement('button');
      //subscribeButton.textContent = `${publication.publisher.id}: ${publication.contentType}`;
      subscribeButton.textContent = `${publication.contentType}`;
      subscribeButton.className = 'videobnt';
      //subscribeButton.className = 'btn btn-outline-primary';
      subscribeButton.setAttribute("type", "hidden");
      buttonArea.appendChild(subscribeButton);

      /*subscribeButton.onclick = async () => {
        const { stream } = await me.subscribe(publication.id);
 
        let newMedia;
        switch (stream.track.kind) {
          case 'video':
            newMedia = document.createElement('video');
            newMedia.playsInline = true;
            newMedia.autoplay = true;
            break;
          case 'audio':
            newMedia = document.createElement('audio');
            newMedia.controls = true;
            newMedia.autoplay = true;
            break;
          default:
            return;
        }
        stream.attach(newMedia);
        remoteMediaArea.appendChild(newMedia);
      };*/
      subscribeButton.onclick = videoAsync(publication);
    };

    room.publications.forEach(subscribeAndAttach);
    room.onStreamPublished.add((e) => subscribeAndAttach(e.publication));
  };
})();

async function videoAsync(publication) {
  const { stream } = await me.subscribe(publication.id);

  let newMedia;
  switch (stream.track.kind) {
    case 'video':
      newMedia = document.createElement('video');
      newMedia.playsInline = true;
      newMedia.autoplay = true;

      var remoteMediaAreaCount = remoteMediaArea.childElementCount ;
      console.log('remoteMediaAreaCount :', remoteMediaAreaCount);
      if (remoteMediaAreaCount > 0) {
        for (let i = 0; i < remoteMediaAreaCount; i++){
          remoteMediaArea.removeChild(remoteMediaArea.childNodes.item(i));
        }
      }
      stream.attach(newMedia);
      remoteMediaArea.appendChild(newMedia);
      break;
    case 'audio':
      newMedia = document.createElement('audio');
      newMedia.controls = true;
      newMedia.autoplay = true;

      var remoteAudioAreaCount = remoteAudioArea.childElementCount ;
      console.log('remoteAudioAreaCount :', remoteAudioAreaCount);
      if (remoteAudioAreaCount > 0) {
        for (let a = 0; a < remoteAudioAreaCount; a++){
          remoteAudioArea.removeChild(remoteAudioArea.childNodes.item(a));
        }
      }
      stream.attach(newMedia);
      remoteAudioArea.appendChild(newMedia);
      break;
    default:
      return;
  }

  //stream.attach(newMedia);
  //remoteMediaArea.appendChild(newMedia);
}


